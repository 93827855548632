const fr = {
    translation: {
        header: {
            "title": "Dashboard {{id}}",
            "list": "Liste des commandes :",
        },
        login: {
            "title": "Login 👋",
            "email.label": "Email",
            "email.placeholder": "you@example.com",
            "password.label": "Mot de passe",
            "submit": "Connexion",
        },
        filter: {
            "titleOrder": "Filtrer par :",
            "titleSites": "Sites :",
            "titleDate": "Date :",
            "today": "Aujourd'hui",
            "week": "7 jours",
            "month": "30 jours",
        },
        order: {
            "numberOrder": "N° commande",
            "dateOrder": "Date",
            "emailOrder": "Email",
            "statusOrder": "Status commande",
            "paiementStatus": "Status paiement",
            "paymentMethod": "Méthode de paiment",
            "shippingStatus": "Status shipping",
            "noresult": "Aucun resultat",
            "actions": "Details",
            "brand": "Marque",
            site: "site"
        },
        chart: {
            "titleBusiness": "Business",
            "titleFluxOrder": "Flux de prise de commande",
            "titleFluxShipping": "Flux d'expédition",
            "titleOrderStats": "Stats Status Commandes 🛍️",
            "titleOrdersShop": "Livraisons Magasin (7j)",
            "titleOrdersFailed": "Failed",
            "titleOrdersNotExported": "Non exportée",
            "titleOrdersNotComplete": "Incomplète",
            "titlePaymentStats": "Stats Moyens de Paiement 💳",
            "titleDeliveryStats": "Stats Moyens de Livraison 🚚",
            "titleOrdersIncomplete": "Commandes Incomplètes",
            "titleOrderShippedNotPaid": "Expédiées non payées (30j)",
            "titleOrdersNumber": "Nombre de commandes",
            "titleOrdersInSAP": "Commandes dans SAP",
            "titleSalesRevenues": "Chiffre d'affaires 💶",
        }
    }
}

export {fr};
